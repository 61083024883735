import { takeEvery, all, call, put } from "redux-saga/effects";
import { store } from "react-notifications-component";
import Router from "next/router";
import { utcToZonedTime, format } from "date-fns-tz";

import recordChangeHistory from "./recordChangeHistory";

import urls from "../../common/urls";

import {
  apiCreateDoc,
  apiUpdateDoc,
  apiGetDoc,
  apiDeleteDoc,
  apiResetDocReads,
  apiCreateDocChangelog,
  apiDetachDocsCommonProcess,
  apiAttachDocsCommonProcess,
  apiUpdateDocsCommonProcess,
} from "./api";

import {
  actionTypes,
  getDocRequest,
  getDocSuccess,
  getDocError,
  saveDocRequest,
  saveDocSuccess,
  saveDocError,
  saveAndPublishDocRequest,
  saveAndPublishDocSuccess,
  saveAndPublishDocError,
  deleteDocRequest,
  deleteDocSuccess,
} from "./actions";

function* createDocSaga(action) {
  try {
    console.log("createDocSaga");
    console.log("action.params", action.params);

    const { docFolderName } = action.params;
    delete action.params.docFolderName;
    const data = action.params;
    const isCopy = action.isCopy;
    const originalDocId = action.originalDocId;

    // Clone aliases data with folder names
    const aliases = data.aliases ? JSON.parse(JSON.stringify(data.aliases)) : [];

    data.aliases?.forEach((alias, i) => {
      // Remove folder name from alias data.
      // folderName needed only for changelog data
      // and does not exist in alias
      delete data.aliases[i].folderName;
    });

    const isPublishedChange = data.isPublished === true || data.isPublished === false;

    if (isPublishedChange) {
      yield put(saveAndPublishDocRequest());
    } else {
      yield put(saveDocRequest());
    }

    const {
      data: { data: createdDoc },
    } = yield call(() => apiCreateDoc(data));

    if (isCopy && originalDocId) {
      // get doc blocks and set docCopyId

      const {
        data: { data: createdDocFullData },
      } = yield call(() => apiGetDoc(createdDoc.id));

      if (createdDocFullData.blocks?.length) {
        const updatedBlocks = createdDocFullData.blocks.map(block => {
          return {
            id: block.id,
            docCopyId: originalDocId
          };
        });  

        yield call(() => apiUpdateDoc({
          id: createdDoc.id,
          blocks: updatedBlocks
        }));
      }
    }

    console.log("created doc", createdDoc);

    console.log("action.changelog", action.changelog);

    const dateUpdated = new Date();
    const timeZone = "Europe/Moscow";
    const zonedDate = utcToZonedTime(dateUpdated, timeZone);
    const actualDate = format(zonedDate, "y-LL-dd kk:mm:ss");

    const _changeLog = {
      docId: createdDoc.id,
      usersUnitId: createdDoc.usersUnitId,
      docTitle: createdDoc.title || "Без названия",
      docFolder: docFolderName,
      text: "Создан новый документ",
      date: actualDate,
      isNew: true,
    };

    if (!createdDoc.docCopyNum) {
      recordChangeHistory({
        mode: "create",
        updatedDoc: createdDoc,
      });
    }

    if (!data.docCopyNum) {
      if (createdDoc.isPublished) {
        const {
          data: { data: changelog },
        } = yield call(() => apiCreateDocChangelog(_changeLog));
      }

      if (aliases.length) {
        aliases.forEach((alias) => {
          const changeLog = {
            docId: createdDoc.id,
            usersUnitId: alias.usersUnitId,
            docTitle: createdDoc.title || "Без названия",
            docFolder: alias.folderName,
            text: "Создан новый документ",
            date: actualDate,
            isNew: true,
          };
          
          apiCreateDocChangelog(changeLog);
        });
      }

      if (isPublishedChange) {
        yield put(saveAndPublishDocSuccess({ isPublished: data.isPublished }));
      } else {
        yield put(saveDocSuccess());
      }

      window.location.href = urls.docs.item(createdDoc.id);

      store.addNotification({
        type: "success",
        message: createdDoc.docCopyNum ? "Копия сохранена" : "Документ сохранен",
        container: "bottom-right",
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
        slidingExit: {
          // disable default slide out animation
          duration: 0,
          delay: 1000,
        },
        dismiss: {
          duration: 3000,
        },
      });
    }
  } catch (error) {
    console.log("CREATE DOC ERROR", error);
    yield put(saveAndPublishDocError());
    yield put(saveDocError());
    store.addNotification({
      type: "danger",
      title: "Ошибка",
      message: "Документ не сохранен",
      container: "bottom-right",
      animationIn: ["animate__animated animate__fadeIn"],
      animationOut: ["animate__animated animate__fadeOut"],
      slidingExit: {
        // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* updateDocSaga(action) {
  try {
    console.log("updateDocSaga");
    console.log("action.articleData", action.articleData);
    console.log("action.changelog", action.changelog);
    console.log("action.docsCommonProcess", action.docsCommonProcess);

    const data = action.articleData;

    // Clone aliases data with folder names
    const aliases = data.aliases ? JSON.parse(JSON.stringify(data.aliases)) : [];

    data.aliases?.forEach((alias, i) => {
      // Remove folder name from alias data.
      // folderName needed only for changelog data
      // and does not exist in alias
      delete data.aliases[i].folderName;
    });

    const isPublishedChange = data.isPublished === true || data.isPublished === false;
    const { docsCommonProcess, actualDate } = action;

    if (docsCommonProcess) {
      const docsCommonProcessId = docsCommonProcess.id;

      yield call(() => apiDetachDocsCommonProcess(docsCommonProcess.id));

      if (docsCommonProcess.items && docsCommonProcess.items.length) {
        const docsIds = docsCommonProcess.items.map((item) => item.docId);

        docsCommonProcess.items.forEach((el) => {
          delete el.doc;
        });

        yield call(() =>
          apiAttachDocsCommonProcess({
            docsCommonProcessId,
            docsIds,
          })
        );

        yield call(() =>
          apiUpdateDocsCommonProcess({
            id: docsCommonProcess.id,
            items: docsCommonProcess.items,
          })
        );
      }
    }

    if (isPublishedChange) {
      yield put(saveAndPublishDocRequest());
    } else {
      yield put(saveDocRequest());
    }

    if (!data.docCopyNum) {
      yield call(() => apiUpdateDoc(data));

      const {
        data: { data: updatedDoc },
      } = yield call(() => apiGetDoc(action.articleData.id));

      console.log("updated doc", updatedDoc);

      if (action.changelog.text) {
        const {
          data: { data: changelog },
        } = yield call(() => apiCreateDocChangelog(action.changelog));

        // Create changelogs
        // for doc aliases teams
        if (aliases.length) {
          const existingAliases = aliases.filter((alias) => alias.docId);

          if (existingAliases.length) {
            existingAliases.forEach((alias) => {
              const changeLog = {
                docId: updatedDoc.id,
                usersUnitId: alias.usersUnitId,
                docTitle: updatedDoc.title || "Без названия",
                docFolder: alias.folderName,
                text: action.changelog.text,
                date: actualDate,
              };
  
              apiCreateDocChangelog(changeLog);
            });
          }
        }
      }

      const newAliases = aliases.filter((alias) => !alias.docId);

      if (newAliases.length) {
        newAliases.forEach((alias) => {
          apiCreateDocChangelog({
            docId: updatedDoc.id,
            usersUnitId: alias.usersUnitId,
            docFolder: alias.folderName,
            docTitle: updatedDoc.title || "Без названия",
            text: "Добавлен новый документ",
            date: actualDate,
          });
        });
      }

      yield call(() => apiResetDocReads(updatedDoc.id));

      if (!updatedDoc.docCopyNum) {
        recordChangeHistory({
          mode: "edit",
          articleData: data,
          updatedDoc,
          oldDocBlocks: action.oldDocBlocks,
          removingBlocks: action.removingBlocks,
        });
      }

      if (isPublishedChange) {
        yield put(saveAndPublishDocSuccess({ isPublished: data.isPublished }));
      } else {
        yield put(saveDocSuccess());
      }

      store.addNotification({
        type: "success",
        message: "Документ сохранен",
        container: "bottom-right",
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
        slidingExit: {
          // disable default slide out animation
          duration: 0,
          delay: 1000,
        },
        dismiss: {
          duration: 3000,
        },
      });

      window.location.reload();
    }
  } catch (error) {
    console.log("UPDATE DOC ERROR", error);

    yield put(saveAndPublishDocError());
    yield put(saveDocError());

    store.addNotification({
      type: "danger",
      title: "Ошибка",
      message: "Документ не сохранен",
      container: "bottom-right",
      animationIn: ["animate__animated animate__fadeIn"],
      animationOut: ["animate__animated animate__fadeOut"],
      slidingExit: {
        // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* getDocSaga({ id, isProject }) {
  try {
    yield put(getDocRequest());

    const {
      data: { data: doc },
    } = yield call(() => apiGetDoc(id, {
      isProject
    }));

    yield put(getDocSuccess(doc));
  } catch (error) {
    console.log("GET DOC ERROR", error);
    yield put(getDocError(error));
  }
}

function* deleteDocSaga(action) {
  try {
    console.log("deleteDocSaga start");

    yield put(deleteDocRequest());

    yield call(() => apiDeleteDoc(action.doc.id));

    yield put(deleteDocSuccess());

    recordChangeHistory({
      mode: "delete",
      updatedDoc: action.doc,
    });

    Router.push(urls.docs.list);

    store.addNotification({
      type: "success",
      message: "Документ удален",
      container: "bottom-right",
      animationIn: ["animate__animated animate__fadeIn"],
      animationOut: ["animate__animated animate__fadeOut"],
      slidingExit: {
        // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 4000,
      },
    });
  } catch (error) {
    console.log("deleteDocSaga ERROR", error);

    yield put(deleteDocSuccess());

    store.addNotification({
      type: "danger",
      title: "Ошибка",
      message: "Документ не удален",
      container: "bottom-right",
      animationIn: ["animate__animated animate__fadeIn"],
      animationOut: ["animate__animated animate__fadeOut"],
      slidingExit: {
        // disable default slide out animation
        duration: 0,
        delay: 1000,
      },
      dismiss: {
        duration: 5000,
      },
    });
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.CREATE_DOC, createDocSaga);
  yield takeEvery(actionTypes.UPDATE_DOC, updateDocSaga);
  yield takeEvery(actionTypes.GET_DOC, getDocSaga);
  yield takeEvery(actionTypes.DELETE_DOC, deleteDocSaga);
}

export default function* root() {
  yield all([watchSagas()]);
}
